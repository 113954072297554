import { LoadingDots } from "@hexa-ui/components";
import { lazy, Suspense } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const ExamplePage = lazy(() => import("./pages/ExamplePage/ExamplePage"));

const BASENAME = "/shelves";
const Router = (): JSX.Element => {
  return (
    <BrowserRouter>
      <Routes>
        <Route
          path={`${BASENAME}/`}
          element={
            <Suspense fallback={<LoadingDots />}>
              <ExamplePage />
            </Suspense>
          }
        />
      </Routes>
    </BrowserRouter>
  );
};
export default Router;
